.filter-sort {
    margin-top: 10px;
}
.filter-sort p {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: black;
}
.filter-sort .list-group {
    display: flex;

}
.filter-sort .list-group button {
    width: 100%;
    background-color: #FCFCFC;
    border: 2px solid #F1F1F1;
}
.filter-sort .list-group button:hover {
    background-color: #F1F1F1;
}
.filter-sort .list-group button:active {
    border: 2px solid #F1F1F1;
}
.filter-sort .list-group button span {
    display: flex;
    justify-content: space-between;
    color: #A8A8A8;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
    padding: 5px 10px;
}
.filter-sort .list-group button span svg{
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    transform: scale(1.3);
}
.filter-sort .list-group ul {
    width: 100%;
    font-size: 17px;
    padding: 0px !important;
    height: 270px;
    overflow-y: auto;
}
.filter-sort .list-group ul item {
    display: block;
    padding: 2px 0px;
    cursor: pointer;
}
.filter-sort .list-group ul item:hover {
    background-color: #A8A8A8;
}
.hide-list {
    display: none;
}