.footer {
    background-color: #737373 !important;
    height: 200px;
    border-radius: 0px;
    padding: 20px 100px 0px 150px
}
.footer .footer-logo {
    width: 400px;
}
.footer .footer-logo p {
    font-size: 13px;
    opacity: 0.5;
    color: white;
    text-align: left;
}
.footer .footer-logo .autocar {
    text-align: left;
    display: flex;
    text-align: center;

}
.footer .footer-logo .autocar span:first-child {
    color: red;
    font-size: 23px;
    font-weight: 700;
    letter-spacing: 1px;
}
.footer .footer-logo .autocar span:last-child {
    color: white;
    font-size: 23px;
    font-weight: 700;
    letter-spacing: 1px;
}
.footer .footer-list ul {
    color: white;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 900px;
}