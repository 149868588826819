.newCarLogo {
    width: 100%;
    height: 400px;
    background-image: url('../../../public/car-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.newCarLogo p:first-child {
    padding: 160px 240px 0px 240px;
    text-align: left;
    color: white;
    font-weight: 600;
}
.newCarLogo p:last-child {
    font-size: 15px;
    color: white;
    font-weight: 500;
    width: 920px;
    text-align: left;
    padding-left: 240px;
}
.detail-body {
    display: flex;
    background-color: #FAFAFA;
    padding: 50px 100px;
    justify-content: center;
}
.detail-body .filter {
    background-color: white;
    width: 350px;
    padding: 10px 20px;
    border-width: 10px;
    border-color: #FAFAFA;
}
.detail-body .display {
    width: 70%;
    padding: 10px 10px;
}


.avatar {
    display: flex;
    width: 300px;
    justify-content: space-between;
}
.avatar .user-picture {
    width: 85px;
}