.main {
    display: block;
}
.main .filter {
    width: 100%;
    font-size: 17px;
    color: black;
    letter-spacing: 1px;
    font-weight: 700;
    border-bottom: 1px solid black;
    padding: 10px 0px;
    border-top: white;
    border-left: white;
    border-right: white;
}
.main .filter p {
    text-align: left;
}
