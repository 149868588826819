.category {
    opacity: 0.3;
    font-size: 15px;
    color: black;
    text-align: left;
    font-weight: 600;
}
.info {
    opacity: 1;
    font-size: 15px;
    color: black;
    text-align: left;
    font-weight: 700;
}
.subCarInfo {
    margin-top: 10px;
}