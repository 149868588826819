
.main-body {
    display: flex;
    height: 170px;
    background-color: white;
    border-bottom: 20px solid #FAFAFA;
}
.picture {
    width: 180px;
    background-image: url('../../../public/car.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.display-detail {
    width: 80%;
    padding-left: 20px;
}
.display-detail .car-name {
    font-size: 17px;
    color: black;
    font-weight: 700;
    text-align: left;
    margin-top: 10px;
}
.display-body {
    display: flex;
}
.display-body .milage_intake {
    width: 25%;
}
.display-body .transmission_power {
    width: 30%;
}
.display-body .year_color {
    width: 30%;
}
.display-body .view_car {
    width: 15%;
    position: relative;
}
.display-body .view_car button {
    width: 80%;
    position: absolute;
    height: 35px;
    bottom: 2px;
}
.display-body .view_car button span {
    padding: 5px 5px;
}